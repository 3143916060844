import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BlogHeader from "../components/blog/BlogHeader"
import BlogPostList from "../components/blog/BlogPostList"
import BlogFooter from "../components/blog/BlogFooter"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  padding-bottom: 2.8125rem;
  background: ${props => props.theme.roomColors[7].base};
`

const Inner = styled.div`
  position: relative;
  z-index: 10;
  max-width: 40rem;
  margin: 0 auto;
  padding: 0 0.75rem;

  h2 {
    margin: 1.5rem 0.25rem 0.75rem;
    font-size: 1.75rem;
    line-height: 1.4;
    font-weight: bold;
    color: white;
  }

  > p {
    margin: 0 0.25rem 1.5rem;
    font-size: 0.875rem;
    color: #eee;
  }

  @media (min-width: 640px) {
    h2 {
      font-size: 2rem;
    }

    > p {
      font-size: 1rem;
    }
  }
`

export default function BlogStartPageTemplate({
  data: { klaraAndOssiYaml: { title, profile_pic, header_pic, posts }, otherBlog },
  pageContext: { lang, pagePath, author, aboutLink },
}) {
  const intl = useIntl()
  const headerPicFileType = (header_pic.toLowerCase().endsWith(".jpg") ? "jpeg" : header_pic.split(".").slice(-1)[0]).toLowerCase()

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={title}
        image={{
          url: `${header_pic}?nf_resize=fit&w=200`,
          alt: `${intl.formatMessage({ id: "portraitOf" })} ${author}`,
          type: `image/${headerPicFileType}`,
          width: 200,
          height: 200,
        }}
      />
      <Header
        lang={lang}
        title={title}
        room={7}
      />
      <BlogHeader
        profilePic={profile_pic}
        headerPic={header_pic}
        link={aboutLink}
        author={author}
      />
      <Inner>
        <h2>{title}</h2>
        <p>{intl.formatMessage({ id: "selectAPost" })}</p>
        <BlogPostList lang={lang} path={pagePath} posts={posts} />
        <BlogFooter
          lang={lang}
          title={otherBlog.title}
          path={otherBlog.path}
          profilePic={otherBlog.header_pic}
        />
      </Inner>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String, $author: String, $otherBlog: String) {
    klaraAndOssiYaml(author: { eq: $author }, lang: { eq: $lang }) {
      title
      profile_pic
      header_pic
      posts {
        title
        images {
          image
        }
        body {
          html
        }
      }
    }
    otherBlog: klaraAndOssiYaml(author: { eq: $otherBlog }, lang: { eq: $lang }) {
      title
      path
      header_pic
    }
  }
`
