import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import arrowDarkGrayRight from "../../images/arrow-dark-gray-right.svg"

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 2.8rem;
  padding-right: 5rem;
  background: rgba(255, 255, 255, 0.8);
  color: #333;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
  }

  img {
    margin-right: 0.375rem;
  }
`

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: 1rem;
  line-height: 2.25rem;
  color: #333;
  font-weight: bold;

  &:hover {
    color: #333;
  }
`

const ProfilePic = styled.span`
  position: absolute;
  bottom: 0.5rem;
  left: calc(100% + 0.5rem);
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);

  @media (max-width: 480px) {
    width: 3.5rem;
    height: 3.5rem;
  }
`

const BlogFooter = ({ lang, title, path, profilePic }) => {
  const intl = useIntl()

  return (
    <Wrapper>
      <StyledLink to={`/${lang}${path}`}>
        <img src={arrowDarkGrayRight} alt="" role="none" />
        {intl.formatMessage({ id: "to" })} {title}
        <ProfilePic style={{ backgroundImage: `url(${profilePic})` }} />
      </StyledLink>
    </Wrapper>
  )
}

export default BlogFooter
