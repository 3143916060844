import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import get from "lodash/get"

import getSlugFromString from "../../utils/get-slug-from-string"

const Wrapper = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`

const StyledLink = styled(Link)`
  display: block;
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
  color: black;
  text-decoration: none;

  &.has-image {
    padding-right: 8rem;
  }

  @media (min-width: 640px) {
    &.has-image {
      padding-right: 9.25rem;
    }
  }
`

const Post = styled.li`
  position: relative;
  max-height: 7.1875rem;
  margin-bottom: 0.75rem;
  overflow: hidden;
  background: white;
`

const Title = styled.h3`
  margin: 0 0 0.25rem;
  font-size: 1rem;
  font-weight: bold;

  @media (min-width: 640px) {
    font-size: 1.125rem;
  }
`

const Text = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.4;

  p,
  ol {
    display: none;
  }

  p:first-child {
    display: block;
    margin: 0;
  }

  @media (min-width: 640px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0.3125rem;
  right: 0.3125rem;
  width: 7.25rem;
  height: calc(100% - 0.625rem);
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (min-width: 640px) {
    width: 8.5rem;
  }
`

export default function PostList({ path, lang, posts }) {
  const postsWithId = posts.map((post, index) => ({ id: index, ...post }))
  return (
    <Wrapper>
      {postsWithId.map(({ id, title, body, images }, index) => (
        <Post key={id}>
          <StyledLink
            to={`/${lang}${path}/${(index + 1)}-${getSlugFromString(title)}`}
            className={images && images.length > 0 ? "has-image" : ""}
          >
            <Title>{title}</Title>
            {images && images.length > 0 &&
              <ImageWrapper style={{ backgroundImage: `url(${encodeURI(images[0].image)}?nf_resize=fit&w=232)` }} />
            }
            <Text dangerouslySetInnerHTML={{ __html: get(body, "html") }} />
          </StyledLink>
        </Post>
      ))}
    </Wrapper>
  )
}
