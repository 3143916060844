import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import blogAboutIcon from "../../images/blog-about-icon.svg"

const Wrapper = styled.div`
  min-height: 9rem;
`

const Inner = styled.div`
  position: fixed;
  top: 3rem;
  left: 0;
  z-index: 2;
  width: 100%;
  min-height: 9rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

const ProfilePic = styled.div`
  position: absolute;
  bottom: ${props => props.small ? -1.5 : 1}rem;
  right: 1rem;
  width: ${props => props.small ? 6.5 : 8.75}rem;
  height: ${props => props.small ? 6.5 : 8.75}rem;
  border: 0.3125rem solid white;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  transition: opacity 0.2s ease;

  ${props => props.hide && `
    opacity: 0;
  `}

  @media (min-width: 50em) {
    transform: translateX( 50% );
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  left: 0.5rem;
  bottom: 1rem;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #eee;
  text-decoration: none;
  transition: opacity 0.2s ease;

  &:hover {
    color: #eee;
  }

  &.home-link {
    padding-left: 1.875rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5rem;
      height: 1.5rem;
      background: url(${blogAboutIcon}) no-repeat center;
      background-size: contain;
    }
  }

  &.back-link {
    padding-left: 0.25rem;

    ::before {
      content: "➔";
      position: relative;
      top: -0.0625rem;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: middle;
      transform: rotate(180deg);
      font-size: 0.6rem;
      line-height: 1.25rem;
    }
  }

  &.hide {
    opacity: 0;
  }
`

const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4.5rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, transparent 100%);

  .inner {
    position: relative;
    height: 100%;
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
  }
`

const Header = ({ link, headerPic, profilePic, author, isBackLink = false }) => {
  const intl = useIntl()
  const [scrollY, setScrollY] = useState(0)
  const headerHeight = `calc(21.625rem - ${scrollY}px)`
  const hideHeaderContent = scrollY > 170
  const title = isBackLink ?
    intl.formatMessage({ id: "back" }) :
    `${intl.formatMessage({ id: "about" })} ${author}`

  const handleScroll = () => {
    setScrollY(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => { window.removeEventListener("scroll", handleScroll)}
  })

  return (
    <Wrapper style={{ height: "21.625rem" }}>
      <Inner style={{ backgroundImage: `url(${headerPic})`, height: headerHeight }}>
        <GradientOverlay>
          <div className="inner">
            <StyledLink
              to={link}
              className={`${isBackLink ? "back-link" : "home-link"} ${hideHeaderContent ? "hide" : ""}`}
            >
              <span>{title}</span>
            </StyledLink>
            <ProfilePic hide={hideHeaderContent} style={{ backgroundImage: `url(${profilePic})` }} />
          </div>
        </GradientOverlay>
      </Inner>
    </Wrapper>
  )
}

export default Header
